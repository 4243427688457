import React, { Component } from 'react';
import Fade from 'react-reveal';

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const profilepic = 'images/' + this.props.data.image;
    const profilepic2 = 'images/' + this.props.data.image2;
    const bio = this.props.data.bio;
    const bio2 = this.props.data.bio2;
    const bio3 = this.props.data.bio3;
    const bio4 = this.props.data.bio4;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="two columns">

             <div className="profile-pic-1"> <img className="profile-pic" src={profilepic} alt="Wei Wei Pic" /></div>
             <div><img className="profile-pic" src={profilepic2} alt="Paul Pic" /></div>
            </div>
            <div className="ten columns main-col">
              <div className="row">
                <div className="twelve columns">
                  <h2>Wei Wei</h2>
                  <p>{bio}<br></br>{bio2}</p>
                </div>

                <div className="twelve columns">
                  <h2>Paul</h2>
                  <p>{bio3}<br></br>{bio4}</p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
