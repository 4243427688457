import React, { Component } from 'react';
import { Fade, Slide } from 'react-reveal';
import emailjs from '@emailjs/browser';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactName: '',
      contactEmail: '',
      contactSubject: '',
      contactMessage: '',
      // Add other state variables if needed
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;

    if (!this.state.contactEmail || !this.state.contactName || !this.state.contactMessage) {
      alert('Please fill all the required fields!');
      return;
    }

    if (!emailPattern.test(this.state.contactEmail)) {
      alert('Please enter a valid email address!');
      return;
    }
    this.setState({ isLoading: true });

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          user_name: this.state.contactName,
          user_email: this.state.contactEmail,
          message: this.state.contactMessage,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          alert('Email sent successfully!');
          this.setState({
            emailSent: true,
            contactName: '',
            contactEmail: '',
            contactSubject: '',
            contactMessage: '',
            isLoading: false,
          });
        },
        (error) => {
          alert('There was an error sending the email.');
          this.setState({ isLoading: false });
        }
      );
  }

  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const email = this.props.data.email;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const message = this.props.data.contactmessage;

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{message}</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="eight columns">
              <form action="" method="post" id="contactForm" name="contactForm">
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactName"
                      name="contactName"
                      onChange={this.handleChange}
                      value={this.state.contactName}
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                      onChange={this.handleChange}
                      value={this.state.contactEmail}
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="contactSubject">Subject</label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactSubject"
                      name="contactSubject"
                      onChange={this.handleChange}
                      value={this.state.contactSubject}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      Message <span className="required">*</span>
                    </label>
                    <textarea
                      cols="50"
                      rows="15"
                      id="contactMessage"
                      name="contactMessage"
                      onChange={this.handleChange}
                      value={this.state.contactMessage}
                      required
                    ></textarea>
                  </div>

                  <div>
                    <button className="submit" onClick={this.handleSubmit}>
                      Submit
                    </button>
                    <span>
                      {this.state.isLoading && <img alt="Loading" src="images/loader.gif" />}
                      {this.state.emailSent && <p>Email successfully sent!</p>}
                    </span>
                  </div>
                </fieldset>
              </form>

              <div id="message-warning"> Error boy</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Your message was sent, thank you!
                <br />
              </div>
            </div>
          </Slide>

          <Slide right duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                <h4>www.PictureThisWei.com.au</h4>
                <p className="address">
                  <a href={`mailto:${email}`}>{email}</a>
                  <br />
                  {street} <br />
                  {city}, {state} {zip}
                  <br />
                  <span>{phone}</span>
                </p>
              </div>
            </aside>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
